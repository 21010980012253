import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Hypnosis } from "react-cssfx-loading";
import "./componentsImports";
import {
  HomePage,
  PageNotFoundPage,
  CommonRoutes,
  AdminRoutes,
  EnterpriseRoutes,
} from "./componentsImports";
import { useSelector } from "react-redux";
import { selectConnectedUser } from "./Redux/slices/sessionSlice";

function App() {
  const user = useSelector(selectConnectedUser);
  if (user.roleUser === "disconnected") {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <Hypnosis
              className="mx-auto my-auto"
              color="#7069E6"
              width="100px"
              height="100px"
              duration="3s"
            />
          }
        >
          <Switch>
            {CommonRoutes.map((route, key) => (
              <Route
                key={key}
                exact={route.isExact}
                path={route.path}
                render={(props) => <route.Component>{props}</route.Component>}
              ></Route>
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  } else if (user.roleUser === "admin") {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <Hypnosis
              className="mx-auto my-auto"
              color="#7069E6"
              width="100px"
              height="100px"
              duration="3s"
            />
          }
        >
          <Switch>
            {AdminRoutes.map((route, key) => (
              <Route
                key={key}
                exact={route.isExact}
                path={route.path}
                render={(props) => <route.Component>{props}</route.Component>}
              ></Route>
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  } else if (user.roleUser === "societe") {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <Hypnosis
              className="mx-auto my-auto"
              color="#7069E6"
              width="100px"
              height="100px"
              duration="3s"
            />
          }
        >
          <Switch>
            {EnterpriseRoutes.map((route, key) => (
              <Route
                key={key}
                exact={route.isExact}
                path={route.path}
                render={(props) => <route.Component>{props}</route.Component>}
              ></Route>
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <Hypnosis
              className="mx-auto my-auto"
              color="#7069E6"
              width="100px"
              height="100px"
              duration="3s"
            />
          }
        >
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <HomePage {...props} />}
            ></Route>

            <Route
              path="*"
              render={(props) => <PageNotFoundPage {...props} />}
            ></Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
