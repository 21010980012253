import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  connectedUser: { roleUser: "disconnected" },
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    chnageConenctedUser(state, action) {
      state.connectedUser = action.payload;
    },
    refreshToken(state, action) {
      // Update the token while keeping the old values
      state.connectedUser = { ...state.connectedUser, token: action.payload };
    },
  },
});

export const selectConnectedUser = (state) => {
  return state.session.connectedUser;
};

export const { chnageConenctedUser, refreshToken } = sessionSlice.actions;

export default sessionSlice.reducer;
