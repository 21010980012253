import React from "react";

export const PublicFormPage = React.lazy(() =>
  import("./components/PublicQuestions/form")
);
export const PublicQuizPage = React.lazy(() =>
  import("./components/publicQuizDash")
);
export const HomePage = React.lazy(() => import("./components/Home"));
export const LoginPage = React.lazy(() => import("./components/auth/login"));
export const LogoutPage = React.lazy(() => import("./components/auth/logout"));
export const DashboardPage = React.lazy(() =>
  import("./components/dashboard/Dashboard")
);
export const PageNotFoundPage = React.lazy(() => import("./pageNotFound"));
export const ConfidentialityPage = React.lazy(() =>
  import("./components/Confidentiality")
);
export const EventBuildingPage = React.lazy(() =>
  import("./components/dashboard/eventBuilding")
);
export const EventDashboardPage = React.lazy(() =>
  import("./components/dashboard/events")
);
export const EnterprisesPage = React.lazy(() =>
  import("./components/dashboard/enterprises")
);
export const EventPage = React.lazy(() => import("./components/Events"));

export const TicketDashboardPage = React.lazy(() =>
  import("./components/dashboard/TicketDashboard")
);
export const TicketHistoryPage = React.lazy(() =>
  import("./components/dashboard/TicketHistory")
);
export const DatabasePage = React.lazy(() =>
  import("./components/dashboard/database")
);
export const AllInformationPage = React.lazy(() =>
  import("./components/dashboard/AllInformation")
);
export const ProtoTypePage = React.lazy(() => import("./components/ProtoType"));
export const QuizBuilderPage = React.lazy(() =>
  import("./components/ProtoType/QuizBuilder")
);
export const DeleteUserInfoQnAPage = React.lazy(() =>
  import("./components/deleteInformation")
);
export const EventComponent = React.lazy(() =>
  import("./components/Events/index")
);
export const EnterPriseDashboard = React.lazy(() =>
  import("./components/EnterpriseDashboard/mainPage/index")
);
export const Stats = React.lazy(() =>
  import("./components/EnterpriseDashboard/stats")
);
export const Lottery = React.lazy(() =>
  import("./components/EnterpriseDashboard/lottery/index")
);
export const CommonRoutes = [
  {
    path: "/",
    Component: HomePage,
    isExact: true,
  },
  {
    path: "/events/:id",
    Component: EventComponent,
    isExact: false,
  },
  { path: "/confidentialite", Component: ConfidentialityPage, isExact: false },
  {
    path: "/faq/user",
    Component: DeleteUserInfoQnAPage,
    isExact: false,
  },
  {
    path: "/login",
    Component: LoginPage,
    isExact: false,
  },
  {
    path: "/public/form/:publicId",
    Component: PublicFormPage,
    isExact: false,
  },
  { path: "*", Component: PageNotFoundPage, isExact: false },
];

export const AdminRoutes = [
  { path: "/logout", Component: LogoutPage, isExact: false },
  {
    path: "/dashboard",
    Component: DashboardPage,
    isExact: true,
  },
  {
    path: "/dashboard/ticket",
    Component: TicketDashboardPage,
    isExact: false,
  },
  {
    path: "/dashboard/hitoriquedesticket",
    Component: TicketHistoryPage,
    isExact: false,
  },
  { path: "/dashboard/dataBase", Component: DatabasePage, isExact: false },
  { path: "/dashboard/publicQuiz", Component: PublicQuizPage, isExact: false },
  {
    path: "/dashboard/enterprises",
    Component: EnterprisesPage,
    isExact: false,
  },
  { path: "/dashboard/events", Component: EventDashboardPage, isExact: false },
  {
    path: "/dashboard/buildQuiz/:id",
    Component: EventBuildingPage,
    isExact: false,
  },

  ...CommonRoutes, // Include common routes for admin
];

export const EnterpriseRoutes = [
  { path: "/logout", Component: LogoutPage, isExact: false },
  {
    path: "/dashboard/myEnterprise",
    Component: EnterPriseDashboard,
    isExact: true,
  },
  {
    path: "/dashboard/myEnterprise/tirage",
    Component: Lottery,
    isExact: true,
  },
  {
    path: "/stats/:quizId",
    Component: Stats,
    isExact: false,
  },

  ...CommonRoutes, // Include common routes for admin
];
